import React from "react"

const BannerTitleNew = ({ props, className }) => (
  <div
    className={`flex justify-center gap-x-5 ${props.divStyle} w-full ${className}`}
  >
    {props.doubleDivider ? (
      <>
        {props.divider}
        {props.text}
        {props.divider}
      </>
    ) : props.dividerRight ? (
      <>
        {props.text}
        {props.divider}
      </>
    ) : (
      <>
        {props.divider}
        {props.text}
      </>
    )}
  </div>
)

export default BannerTitleNew
