import React from "react"

import { Link } from "gatsby"

const ButtonLink = ({
  to = "/#",
  text = null,
  className = "",
  children = null,
}) => {
  // Check if link is external
  const isExternal = to && to.split("/")[0] !== ""

  return isExternal ? (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={`font-normal px-3 py-1  bg-tertiary  text-white hover:bg-white hover:text-tertiary  ${className}`}
    >
      {text}
    </a>
  ) : (
    <Link
      className={` font-normal px-3 py-1  bg-tertiary  text-white hover:bg-white hover:text-tertiary  ${className}`}
      to={to}
    >
      {text}
      {children}
    </Link>
  )
}

export default ButtonLink
