import React from "react"

const ShowMoreButton = ({
  addMoreCards = () => console.log("Add More!"),
  className,
  text = "Show More",
}) => {
  return (
    <button
      onClick={() => addMoreCards()}
      className={`font-normal px-3 py-1  bg-tertiary text-xl font-bold tracking-wide  text-white hover:bg-white hover:text-tertiary justify-self-center mt-10 ${className}`}
    >
      {text}
    </button>
  )
}

export default ShowMoreButton
