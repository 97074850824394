import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import ButtonLink from "./ButtonLink"
import { FaArrowRight } from "react-icons/fa"
import ShowMoreButton from "./ShowMoreButton"

export const SingleEventCard = ({
  title = "Event Title",
  description = "Event description",
  time = "7:00pm",
  month = "Dec",
  day = "02",
  year = "1999",
  darkText = false,
  isSingleEvent = false,
  registrationLink = null,
  slug = null,
  eventImage = null,
}) => {
  const { defaultImage } = useStaticQuery(graphql`
    query EventCardQuery {
      defaultImage: file(relativePath: { eq: "WINKS Logo - Circle Only.png" }) {
        id
        childImageSharp {
          gatsbyImageData(height: 200, aspectRatio: 1, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <div
      className={`p-8 ${
        darkText ? "bg-primary" : "bg-white"
      } bg-opacity-5 rounded   mx-5  `}
    >
      <div className=" flex flex-col md:flex-row min-w-xs md:min-w-xl max-w-4xl">
        {/* Event Image */}
        <div className="relative mb-5 md:mb-0 md:mr-10  self-center md:self-start">
          <GatsbyImage
            image={eventImage || defaultImage.childImageSharp.gatsbyImageData}
            className="w-28 h-28 rounded-full"
            alt=""
          />

          {!isSingleEvent && (
            <div className="absolute -bottom-3 -right-3 w-14 h-14 bg-white text-primary grid grid-rows-2 justify-items-center items-center uppercase font-semibold leading-snug">
              <span>{month.slice(0, 3)}</span>
              <span className="text-xl">{day}</span>
            </div>
          )}
        </div>
        {/* Right hand side container */}

        <div
          className={`mt-2 ${darkText ? "text-primary" : "text-quaternary"}`}
        >
          <div className="text-center md:text-left  text-2xl  mb-2 ">
            {!isSingleEvent && (
              <>
                <span className="font-semibold  ">{title}</span>
                <span className="mx-2">|</span>
              </>
            )}
            <span>{time}</span>
            {isSingleEvent && (
              <>
                {" "}
                <span className="mx-2">|</span>
                <span className="font-semibold ">
                  {month} {day}, {year}
                </span>
              </>
            )}
          </div>
          <div>{description}</div>
        </div>
      </div>
      {!isSingleEvent && slug && (
        <div className="flex justify-end">
          <ButtonLink
            text={null}
            className="mt-3 text-xl flex items-center"
            to={`/events/${slug}`}
          >
            More Info <FaArrowRight className="ml-3" />
          </ButtonLink>
        </div>
      )}
      {isSingleEvent && registrationLink && (
        <div className="flex justify-end">
          <ButtonLink
            text="Register"
            className="mt-3 mx-3 px-5 text-xl"
            to={registrationLink}
          ></ButtonLink>
        </div>
      )}
    </div>
  )
}

export const EventCardContainer = ({ children }) => {
  return (
    <div className="grid items-center justify-center space-y-16 mt-10">
      {children}
    </div>
  )
}

const EventCards = ({
  children,
  eventData = null,
  darkText = false,
  isSingleEvent = false,
  limit = 5,
  hideShowMoreButton = false,
}) => {
  const [numCards, setNumCards] = useState(limit)

  function addMoreCards() {
    return setNumCards(numCards + 5)
  }

  if (eventData) {
    eventData = eventData.slice(0, numCards)
    return (
      <EventCardContainer>
        {eventData.map((event, index) => {
          const {
            title = "Title Missing",
            eventTime = "Time Missing",
            month,
            day,
            year,
            eventDescription = "No description",
            registrationLink = "",
            slug = null,
            eventImage = null,
          } = event

          return (
            <SingleEventCard
              title={title}
              time={eventTime}
              month={month}
              day={day}
              year={year}
              description={eventDescription}
              darkText={darkText}
              isSingleEvent={isSingleEvent}
              registrationLink={registrationLink}
              slug={slug}
              eventImage={eventImage}
              key={`${index}-${title}`}
            ></SingleEventCard>
          )
        })}
        {/* Only show button when there are more cards to show */}
        {numCards >= 1 &&
          numCards <= eventData.length &&
          !hideShowMoreButton && (
            <ShowMoreButton text="More Events" addMoreCards={addMoreCards} />
          )}
      </EventCardContainer>
    )
  } else {
    return (
      <div className="text-3xl text-center mx-5">
        <p>Sorry no events yet!</p>
        <p> Please check again soon</p>
      </div>
    )
  }
}

export default EventCards
