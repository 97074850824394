import React from "react"
import SEO from "../components/SEO"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import BlockContentPrimary from "../components/block content/blockContentPrimary"
import BackgroundVector from "../components/backgroundVector"
import ContactForm from "../components/contactForm"
import Hero, { HeroTitleDescription } from "../components/Hero"
import PageContentContainer from "../components/PageContentContainer"
import BannerContainer from "../components/bannerContainer"
import WaveDivider from "../components/svgs/WaveDivider"
import BannerTitleNew from "../components/BannerTitleNew"
import EventCards from "../components/EventCard"
import { formatTime, monthArray, reshapeEventData } from "../helperFunctions"
import {
  DownloadsTable,
  ResourcesTableContainer,
  WebsitesTable,
} from "../components/resources/ResourcesTables"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import ExternalLink from "../components/ExternalLink"

const ScrollLink = ({ children, to = "#", className }) => (
  <Link
    to={`/${to}`}
    className={`text-secondary hover:text-quaternary  rounded-lg px-5 py-1 underline font-bold ${className}`}
  >
    {children}
  </Link>
)

const ResourcesPage = ({ data }) => {
  const { resourcesPage } = data

  const { websites, downloads } = resourcesPage
  const bannerFluid = getImage(data?.defaultHeroImage)

  const sanityHeroFluid = resourcesPage?.heroImage?.asset?.gatsbyImageData

  const bgImage = convertToBgImage(sanityHeroFluid || bannerFluid)

  // const heroFluid = getImage(data?.defaultHeroImage) || null
  // const convertedHeroImage = convertToBgImage(heroFluid).fluid

  // shape data to work with EventCards component

  // For each event, extract info and put it in correct structure:

  const heroProps = {
    heroFluid: bgImage?.fluid,
  }

  return (
    <Layout>
      <SEO title={resourcesPage.title} />
      <Hero props={{ ...heroProps }}>
        <HeroTitleDescription
          title={resourcesPage.title}
          text="Our community offers many valuable resources for residents."
        />
      </Hero>
      <PageContentContainer>
        {/* PROGRAMS */}
        <BannerContainer color="bg-primary pt-10 pb-20" id="programs">
          <div className="flex flex-col md:flex-row text-center gap-5  justify-center flex-wrap text-2xl mb-10">
            {downloads.length > 0 && (
              <ScrollLink to="resources/#downloads">Downloads</ScrollLink>
            )}
            {websites.length > 0 && (
              <ScrollLink to="resources/#websites">Websites</ScrollLink>
            )}
          </div>
          <BannerTitleNew
            props={{
              divider: (
                <WaveDivider className=" fill-tertiary w-20 md:w-32 hidden md:block" />
              ),
              divStyle: "h-1/6 w-full place-items-center",
              doubleDivider: true,
              text: (
                <div className="grid justify-items-center w-full md:w-auto">
                  <p className="text-4xl text-center  font-semibold">
                    Programs & Organizations
                  </p>
                  <WaveDivider className=" fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                </div>
              ),
            }}
          />
          <div>
            {resourcesPage.resources.map((resource, index) => (
              <div className="py-10  rounded-lg bg-white bg-opacity-10 my-10 mx-5">
                <ResourcesTableContainer key={`${resource._key}-${index}`}>
                  {resource.url ? (
                    <>
                      <div className="text-center md:text-left">
                        <h2 className=" text-secondary font-bold">
                          {resource.title}
                        </h2>

                        <ExternalLink to={resource.url}>
                          {resource.url}
                        </ExternalLink>
                      </div>
                    </>
                  ) : (
                    <h2 className="text-center md:text-left text-secondary font-bold">
                      {resource.title}
                    </h2>
                  )}
                  <div className="mt-5">
                    <BlockContentPrimary blockData={resource._rawDetails} />
                  </div>
                </ResourcesTableContainer>
              </div>
            ))}
          </div>
        </BannerContainer>

        {/* WEBSITES */}
        {websites.length > 0 && (
          <BannerContainer color="bg-secondary" className="py-20" id="websites">
            <BannerTitleNew
              props={{
                divider: (
                  <WaveDivider className=" fill-tertiary w-20 md:w-32 hidden md:block" />
                ),
                divStyle: "h-1/6 w-full place-items-center",
                doubleDivider: true,
                text: (
                  <div className="grid justify-items-center w-full  md:w-auto">
                    <p className="text-3xl md:text-4xl font-semibold text-primary">
                      Websites
                    </p>
                    <WaveDivider className=" fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                  </div>
                ),
              }}
            />
            <ResourcesTableContainer>
              <WebsitesTable websites={websites} />
            </ResourcesTableContainer>
          </BannerContainer>
        )}

        {/* DOWNLOADS */}
        {downloads.length > 0 && (
          <BannerContainer color="bg-primary" className="py-20" id="downloads">
            <BannerTitleNew
              props={{
                divider: (
                  <WaveDivider className=" fill-tertiary w-20 md:w-32 hidden md:block" />
                ),
                divStyle: "h-1/6 w-full place-items-center",
                doubleDivider: true,
                text: (
                  <div className="grid justify-items-center w-full  md:w-auto">
                    <p className="text-3xl md:text-4xl  font-semibold">
                      Downloads
                    </p>
                    <WaveDivider className=" fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                  </div>
                ),
              }}
            />{" "}
            <ResourcesTableContainer>
              <DownloadsTable downloads={downloads} />
            </ResourcesTableContainer>
          </BannerContainer>
        )}
      </PageContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query ResourcesPageQuery {
    defaultHeroImage: file(
      relativePath: { eq: "papaioannou-kostas-tysecUm5HJA-unsplash.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    resourcesPage: sanityResourcesPage {
      title
      heroImage {
        asset {
          gatsbyImageData(width: 2000, placeholder: BLURRED)
        }
      }
      resources {
        title
        _key
        url
        _rawDetails(resolveReferences: { maxDepth: 10 })
      }
      downloads {
        title
        description
        file {
          asset {
            _createdAt(formatString: "MMMM D, yy")
            url
            originalFilename
          }
        }
      }
      websites {
        title
        url
        description
      }
    }
  }
`

export default ResourcesPage
