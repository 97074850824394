import React from "react"

const inputClassNames =
  "bg-transparent w-full text-2xl lg:text-4xl text-md text-white placeholder-red-500"

export const INPUT = ({
  name,
  id,
  type,
  placeholder,
  required = false,
  className,
}) => (
  <input
    name={name}
    id={id}
    placeholder={placeholder}
    type={type}
    required={required}
    className={`bg-transparent w-full text-2xl lg:text-3xl text-md text-white placeholder-gray-400 pb-2 mt-2 ${className}`}
  />
)

const Divider = () => <hr className="border-yellow-200 w-full" />

const ContactForm = ({ className }) => {
  return (
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/success"
      className={className}
    >
      {/* If this hidden input isn't included, the form won't get to where it needs to go */}
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      {/* ref: https://cobwwweb.com/how-to-use-netlify-forms-with-gatsby || https://stackoverflow.com/questions/61318577/netify-gatsby-form-submission-not-working-with-custom-success-component*/}
      {/* Don't forget this field - won't work without it! */}
      {/* DOCS: https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#form-handling-with-static-site-generators */}
      <input type="hidden" name="form-name" value="contact" />
      {/* HP */}
      <div className="hidden">
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
        <input name="bot-field" />
      </div>
      {/* End HP */}
      <div className="grid grid-cols-1 w-full justify-items-center">
        <INPUT name="name" id="name" type="text" placeholder="Name" required />

        <Divider />

        <INPUT
          name="email"
          id="form__email"
          type="email"
          placeholder="Email"
          required
        />
        <Divider />

        <INPUT
          name="subject"
          id="form__subject"
          type="text"
          placeholder="Subject"
          required
        />
        <Divider />

        <textarea
          className="bg-transparent w-full text-2xl lg:text-3xl text-md  placeholder-gray-400 text-white pb-2 mt-2  "
          name="message"
          id="form__message"
          placeholder="Message"
          rows="5"
          required
        />
        <Divider />

        <button
          type="submit"
          className=" px-3 py-1  bg-tertiary rounded-3xl text-white hover:bg-white hover:text-tertiary uppercase font-bold tracking-wider text-2xl mt-10 justify-self-end"
        >
          Send
        </button>
      </div>
    </form>
  )
}
export default ContactForm
