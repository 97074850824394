import React from "react"
import { FaDownload } from "react-icons/fa"

const sampleDownloadInfo = [
  {
    title: "Sample File 1",
    date: "01/02/2021",
    url: "https://www.google.ca",
  },
  {
    title: "Sample File 2",
    date: "01/03/2021",
    url: "https://www.google.ca",
  },
  {
    title: "Sample File 3",
    date: "01/04/2021",
    url: "https://www.google.ca",
  },
  {
    title: "Sample File 4",
    date: "01/05/2021",
    url: "https://www.google.ca",
  },
]

const sampleWebsiteInfo = [
  {
    title: "Website title 1",
    description: "Short Description 1",
    url: "https://www.google.ca",
  },
  {
    title: "Website title 2",
    description: "Short Description 2",
    url: "https://www.google.ca",
  },
  {
    title: "Website title 3",
    description: "Short Description 3",
    url: "https://www.google.ca",
  },
  {
    title: "Website title 4",
    description: "Short Description 4",
    url: "https://www.google.ca",
  },
]

const ItemName = ({
  title = "ERROR: Title missing",
  dark = false,
  className = "",
}) => (
  <div
    className={`${
      dark ? "text-primary" : "text-white"
    } text-2xl md:text-base break-words ${className}`}
  >
    {title}
  </div>
)

const FileDate = ({ dateString = "ERROR: Date missing" }) => (
  <div className="text-center">
    <span className="md:hidden ">Added: </span>
    {dateString}
  </div>
)

const ItemLink = ({ url = "/#", green = true }) =>
  green ? (
    <a
      href={`${url}?dl=`}
      className="text-tertiary uppercase hover:underline text-center download flex justify-center items-center"
    >
      <FaDownload />
    </a>
  ) : (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-white uppercase hover:underline"
    >
      Visit
    </a>
  )

const RowDivider = ({ dark = false }) => (
  <div
    className={`col-span-3 border-b-1 ${
      dark ? "border-primary" : "border-secondary"
    } my-3`}
  ></div>
)

const ItemDescription = ({
  text = "Missing Description",
  dark = true,
  className = "",
}) => (
  <div
    className={` font-normal italic ${
      dark ? "text-primary" : "text-white"
    } ${className}`}
  >
    {text}
  </div>
)

const TitleText = ({ children, className, dark = false }) => (
  <div
    className={`${
      dark ? "text-primary" : "text-secondary"
    } italic font-normal hidden md:block ${className}`}
  >
    {children}
  </div>
)

export const ResourcesTableContainer = ({ children, className }) => (
  <div className={` grid justify-items-stretch w-full ${className}`}>
    <div className="max-w-7xl w-11/12 justify-self-center">{children}</div>
  </div>
)

export const DownloadsTable = ({ downloads }) => {
  return (
    <div className=" font-bold grid grid-cols-websites md:grid   md:grid-cols-downloads2 mt-5 ">
      <TitleText>File Name</TitleText>

      <TitleText className=" text-center px-10">Added </TitleText>
      <TitleText className="px-5 text-center">Download</TitleText>
      <RowDivider />

      {downloads &&
        downloads.map(download => {
          return (
            <>
              <div className="md:flex">
                <ItemName title={download.title} />
                <ItemDescription
                  text={download.description || ""}
                  dark={false}
                  className="md:ml-10"
                />
              </div>

              <div className="hidden md:block">
                {" "}
                <FileDate dateString={download.file.asset._createdAt} />
              </div>
              <ItemLink url={download.file.asset.url} />

              <RowDivider />
            </>
          )
        })}
    </div>
  )
}

export const WebsitesTable = ({ websites }) => {
  return (
    <div className="font-bold md:grid grid-cols-1  mt-5 ">
      {websites.map(website => {
        return (
          <div className="grid grid-cols-websites  ">
            <div className="grid items-center md:grid-cols-websites2">
              <div>
                <ItemName className=" w-52" title={website.title} dark />
              </div>
              <div className=" ">
                <ItemDescription
                  text={website.description || ""}
                  className="ml-8"
                />
              </div>
            </div>
            <div className="justify-self-end self-center">
              <ItemLink green={false} url={website.url} className="" />
            </div>
            <RowDivider dark />
          </div>
        )
      })}
    </div>
  )
}
